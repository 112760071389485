import React from "react"

import { Card, CardContent, Typography } from "@mui/material"
import PointOfSale from "@mui/icons-material/PointOfSale"
import PriceCheck from "@mui/icons-material/PriceCheck"
import AccountBalance from "@mui/icons-material/AccountBalance"
import SupportAgent from "@mui/icons-material/SupportAgent"
import CardGiftcard from "@mui/icons-material/CardGiftcard"
import AttachMoney from "@mui/icons-material/AttachMoney"
import TrendingUp from "@mui/icons-material/TrendingUp"

import Layout from "../components/layout"

import CardTitle from "../components/CardTitle"
import SectionTitle from "../components/SectionTitle"
import Seo from "../components/seo"
import SignificantSubtitle from "../components/SignificantSubtitle"
import content from "../helpers/content.json"
import { capitalFirstLetter } from "../helpers/common"
import { pageSetting, theme, palette } from "../helpers/theme"
import { AccessibilityNewIcon, SchoolIcon } from "../helpers/Icons"

const makeArray = str => str.split(".")

const iconProps = { width: "50px !important", height: "50px" }

const keyWord = [
  { title: "doświadczenie", icon: <SchoolIcon css={iconProps} /> },
  { title: "nowoczesność", icon: <PointOfSale css={iconProps} /> },
  { title: "atrakcyjne ceny", icon: <PriceCheck css={iconProps} /> },
  { title: "wysokie ubezpiczenie", icon: <AttachMoney css={iconProps} /> },
  { title: "troska", icon: <AccessibilityNewIcon css={iconProps} /> },
  { title: "wsparcie", icon: <SupportAgent css={iconProps} /> },
  { title: "bezinteresowność", icon: <CardGiftcard css={iconProps} /> },
  { title: "zaangażowanie", icon: <AccountBalance css={iconProps} /> },
  { title: "rozwój", icon: <TrendingUp css={iconProps} /> },
]

const WhyWe = () => {
  const title = content["why-we"].title
  const text = content["why-we"].content
  const arr = makeArray(text)

  return (
    <Layout>
      <Seo title={capitalFirstLetter(title)} />
      <SectionTitle title={title} />
      <SignificantSubtitle
        text="Nasze biuro oferuje pełną gamę profesjonalnych usług, opartych na potrzebach i oczekiwaniach klientów. Zapewniamy profesjonalną obsługę firm i spółek."
        style={{ fontSize: "1.2rem", marginBottom: theme.gutterBig }}
      />
      <div css={styles.cardsHolder}>
        {arr.map((item, i) => (
          <div key={item[i]} css={styles.cardHolder}>
            <Card css={styles.card}>
              <div css={styles.iconBox}>{keyWord[i].icon}</div>
              <CardContent
                css={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <CardTitle title={keyWord[i].title} style={styles.title} />
                <Typography css={styles.text}>{item}</Typography>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </Layout>
  )
}

const styles = {
  root: {
    marginTop: theme.gutterBig * 6,
  },
  cardsHolder: {
    display: "flex",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    height: "100%",
    marginTop: theme.gutterBig * 3,
  },
  cardHolder: {
    transition: "all .5s ease-out",
    position: "relative",
    "&:hover": {
      transform: "scale3d(1.01, 1.01, 1)",
      color: pageSetting.attention,
      "& $child": {
        background: "blue",
      },
    },
  },
  card: {
    // position: 'relative',
    margin: `${theme.gutter}px 0`,
    border: ".5px solid rgba(0, 0, 0, 0.2)",
    maxWidth: 320,
    height: 240,
    transition: "all .5s ease-out",
    "&:hover": {
      color: pageSetting.attention,
    },
    ...pageSetting.text,
  },
  title: {
    fontWeight: 700,
    paddingBottom: theme.gutter,
    fontSize: "1.25rem",
    color: "inherit",
  },
  text: {
    fontSize: "1rem",
    ...pageSetting.text,
  },
  iconBox: {
    position: "absolute",
    display: "inline-block",
    left: -28,
    top: 2,
    border: "1px solid rgba(0, 0, 0, 0.2)",
    borderRadius: 50,
    width: 60,
    height: 60,
    background: palette.white,
  },
}

export default WhyWe
